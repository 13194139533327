import React from 'react'
import Layout from '../../components/Layout'

export default class PrivacyPolicyPage extends React.Component {

  state = {
    checked: false
  }

  handleChange = () => {
    this.setState(  prevState => ({
      checked: !prevState.checked
    }))
  };

  render() {
    return (
      <Layout isProgramTypeSelected= {true}>
        <section className="section-privacy">
          <div>
            <h3 className="section-title mb-text-center">
              Privacy and Policies
            </h3>

            <p>
            Sesquipedalian Theatre-led Learning is a contemporary academic curriculum that brings the principles of the performing arts to a comprehensive language and personality development programme for children between the ages of 5 and 16.

With tools and techniques from the stage to enhance the experience, students can develop their academic, leadership, cognitive and social skills to reach their potential as high performers.
            </p>
              <br/>
            <p>
            Your privacy is important to us. It is Sesquipedalian policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, <a href="https://www.sesquipedalian.lk"></a> https://www.sesquipedalian.lk
            </p>
            <br/>
            <p>
            Personal information is any information about you which can be used to identify and contact you. This includes information about you as a person (such as name, contact number, email and), children’s name and age group.
            </p>
          </div>

          <br />
          <div>
            <h4 className="privacy-sub-title">Third-party cookie privacy policies</h4>
            <p>
            We receive aggregate information about visits to the website from third-parties such as Google Analytics
            </p>
            <br/>
            <p>
            We use cookies for a variety of reasons. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.
            </p>
            <br />
            <p><b>For more information about our website, please contact:</b></p>
            <p>Email: <i>visitus@sesquipedalian.lk</i></p>
          </div>
        </section>
      </Layout>
    )
  }
}
